







































































































































































































































































































































































































































































































































import NewAccount from '@/components/newaccount/newAccount';
import '@/components/newaccount/newAccount.less';
export default NewAccount;

