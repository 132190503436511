import store from '@/store';
import { fetchExistingAccountData, accountDetailResponse, setAccountDetailResponse, updateAccountDetailResponse, autoSaveAccountDetails } from '@/services/account/account';

import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';


@Module({
  namespaced: true,
  name: 'storeInterceptor',
  store,
  dynamic: true
})

class storeInterceptorModule extends VuexModule {

  public objAccountData: any = {};

  get newAccountData(){
    return this.objAccountData;
  }
  @Mutation
  public handleAccountData(payload:any) {
    this.objAccountData = payload.data;
  }

  @Action({commit: 'handleAccountData'})
  public getAccountData(strId:string):Promise<Object>{
    const resultNew = async function fetchAccountData(strId:string): Promise<Object> {
      const promise = accountDetailResponse(strId);
      const result = await promise;  
      return result as object;
    };
    return resultNew(strId);
  }

  @Action({commit: 'handleAccountData'})
  public saveAccountData(accountData:any):Promise<Object>{
    const resultNew = async function saveAcData(accountData:any): Promise<Object> {
      const promise = setAccountDetailResponse(accountData);
      const result = await promise;  
      return result as object;
    };
    return resultNew(accountData);
  }


  @Action({commit: 'handleAccountData'})
  public updateAccountData(accountData:any):Promise<Object>{
    const resultNew = async function saveAcData(accountData:any): Promise<Object> {
      const promise = updateAccountDetailResponse(accountData);
      const result = await promise;  
      return result as object;
    };
    return resultNew(accountData);
  }


  @Action({commit: 'handleAccountData'})
  public autoSaveAccountData(accountData:any):Promise<Object>{
    const resultNew = async function saveAcData(accountData:any): Promise<Object> {
      const promise = autoSaveAccountDetails(accountData);
      const result = await promise;  
      return result as object;
    };
    return resultNew(accountData);
  }

  @Action({commit: 'handleAccountData'})
  public fetchExistingAccountDetails(strId:string):Promise<Object>{
    const resultNew = async function fetchAccountData(strId:string): Promise<Object> {
      const promise = fetchExistingAccountData(strId);
      const result = await promise;  
      return result as object;
    };
    return resultNew(strId);
  }

  
}

export default getModule(storeInterceptorModule);
