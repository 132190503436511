








import NewAccount from '@/components/newaccount/newAccount.vue';
export default {
  name: 'home',
  components: {
    NewAccount
  }
};
